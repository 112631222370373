<template>
  <div class="animated fadeIn">
    <edit-service :type="'outClinic'" :label="'سرویس در منزل'" :back="'/admin/homeServices'"/>
  </div>
</template>
<script>
const EditService = () => import("@/components/services/EditService");

export default {
  components: {
    EditService
  }
};
</script>